/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

body {
  margin: 0;
  font-family: 'Gilroy-Light' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Gilroy-Light',
}


@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./fonts/Gilroy-Light.otf) format('opentype');
}





.parent-sidebar {
  position: relative;
  /* background-color: rgba(35, 55, 75, 0.9);
  color: #fff; */
  padding: 6px 12px;
/*  font-family: monospace;*/
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  /* left: 0; */
  margin-top: 12px;
    /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  float: center;
  height: 100px;
  width: 90%;
  border-radius: 4px;
  background-color: white;
  vertical-align: middle;
}

.child-sidebar {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
/*  font-family: monospace;*/
  z-index: 1;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  /* left: 0; */
  margin: auto;
  display: block;
  width: 99%;
  overflow:hidden;
/*  border: 2px solid blue;*/
  margin-left: 10px;
  margin-right: 10px;
  /* border-radius: 4px; */
/*  background-color: black;*/
}

.child-child-sidebar {
  float: right;
  display: flex;
  font-family: monospace;
  justify-content: flex-end;
  text-align: center;
  align-items: center;
  width: 100%;
  vertical-align: middle;
/*  background-color: gray;*/
}

.input-sidebar {
  
  margin: auto;
  float: right;
  width: auto;
}

.btn-sidebar {
  margin: auto;
  float: right;
  width: auto;
}


.parent-roll {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 6px 12px;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  /* left: 0; */
  margin-top: 12px;
    /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  float: center;
  width: 90%;
  border-radius: 4px;
  background-color: white;
  height: 40vh;
  
}

.child-roll {
  z-index: 1;
  align-items: center;
  justify-content: center;
  margin: auto;
  display: block;
  width: 99%;
  margin-left: 10px;
  margin-right: 10px;
  /* border-radius: 4px; */
  height: 40vh;
}



.invite-container {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  margin: 1rem;
}

.invite-code-snippet {
  width: 300px;
  background: #afb3b8;
  padding: 0.1rem;
}

.invite-code-section {
  position: relative;
  width: 100%;
}

.invite-code-section pre {
  font-size: 120%;
}

.invite-code-section span {
  position: absolute;
  right: 0;
  top: 5%;
  bottom: 0;
  font-size: 120%;
}






.route {
  position: absolute;
  bottom: 0;
  z-index: 5;
  display: flex;
  left: 0;
  margin: auto;
  display: block;
  margin-left: 5%;
  margin-bottom: 5%;
}


.cover-title {
  color: white;
  bottom: 0;
  position: absolute;
  margin-left: 5px; 
  background-color: rgba(52, 52, 52, 0.5);
  line-height: 1.5em;
  max-height: 3em;       /* height is 2x line-height, so two lines will display */
  overflow: hidden;
/*  white-space: nowrap;*/
  text-overflow: ellipsis;  /*   ??????  ;*/
}


.add-map-footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: flex;
  right: 0;
  display: block;
  margin-right: 1%;
  margin-bottom: 1%;
}




.test {
  position: absolute;
  z-index: 0;
  /* top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  height: 50%;
  width: 100%;
  background-color: #03b2cb;
}


.map-container {
  position: absolute;
  z-index: 0;
  top: 0; 
  bottom: 0;
  left: 0;
  right: 0;
/*  background-color: #cb3c03;*/
/*  width: 100%;*/
/*  height: 50%;*/

}


.map-adddirection-sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}


/*
.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}
*/


.ant-modal {
  width: 100%;
  transform-origin: 85px 43px;
  overflow: hidden;
  height: -webkit-fill-available;
}
.ant-modal-content {
  top: 0;
  padding: 0% !important;
  padding-bottom: 5px !important;
}


.ant-modal-footer {
    margin-top: 5px !important;
    margin-right: 10px !important;
    margin-bottom: 5px !important;
  }





.ant-modal-centered::before {
  content: unset;
}

.ant-carousel .slick-dots li {
  background: #03b2cb;
  opacity: 0.8;
}

.ant-carousel .slick-dots li.slick-active {
  opacity: 1;
  background: #40a9ff; 
}

.ant-modal-header {
  height: 40px;
  padding: 0px !important;
}

/* .ant-modal-close {
  width: 40px;
  height: 40px;
} */

/* .ant-modal-close-x {
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
} */


.ant-card-small > .ant-card-body {
  padding: 0px !important;
}

.ant-card-bordered {
  border: 0px solid #f0f0f0;
}

/*
.ant-modal-title {
  height: 30px;
  padding: 5px !important;
}
*/







::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #888888;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #888888;
}

.scroll-box {
  overflow-y: scroll;
  height: 200px;
  border: 1px solid #ccc;
}





.image-gallery-right-nav .image-gallery-svg {
  height: 80px !important;
  width: 40px !important;
}

.image-gallery-left-nav .image-gallery-svg {
  height: 80px !important;
  width: 40px !important;
}

.image-gallery-left-nav:hover {
  color:rgba(0, 0, 0, 0.5) !important
}

.image-gallery-right-nav:hover {
  color:rgba(0, 0, 0, 0.5) !important
}

.image-gallery-icon:hover {
  color:rgba(0, 0, 0, 0.5) !important
}

.image-gallery-thumbnail.active {
  border: 4px solid #000000 !important;
}




.desc2style {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}



.image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 90px;
  line-height: 0;
  height: 60px !important;
}


.image-gallery-slide .image-gallery-image {
  width: 100%;
  height: 400px !important;
  object-fit: cover !important;
}


.image-gallery {
  background-color: #EDF1F2 !important;
  /* background-color: #7f7f7f !important; */
}






.ant-btn-add-to-route {
  background-color: rgba(0, 0, 0, 0.10) !important;
}


.ant-btn-add-to-route:hover {
  background-color: rgba(0, 0, 0, 0.25) !important;
}


.dirs {
  overflow-y: scroll;
  /* height: 100%; */
  width: 100%;
  display: flex;
  background-color: #EDF1F2;
  /* background-color: #7f7f7f; */
  padding-bottom: 200px;
  /* padding-left: 40px;
  padding-right: 40px; */
}


.dirs2 {
  width: 100%;
  height: 100%;
  /* display: flex; */
  /* background-color: #40a9ff; */
  /* background-color: #EDF1F2; */
  padding-bottom: 200px;
  margin-left: 5%;
  margin-right: 5%;
}


.myFavoritesDiv {
  border-radius: 20px; 
  background-color: rgba(255,255,255,0.5);
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-top: 60px;
  padding-bottom: 30px;
}


.myFavoritesDivTitle {
  margin: 20px;
}



.myRouteSummaryBlocks {
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px; 
  background-color: #7f7f7f;
  margin: 5px;
}


.myRouteSummaryBlocksContent {
  font-size: 18px;
  font-weight: bold;
}


