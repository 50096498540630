.balls {
  width: 4em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  vertical-align: 'middle';
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  height:100%;
  top: 50%;
}

.balls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #1890ff;
}

.balls div:nth-of-type(1) {
  transform: translateX(-100%);
  animation: left-swing 0.5s ease-in alternate infinite;
}

.balls div:nth-of-type(3) {
  transform: translateX(-95%);
  animation: right-swing 0.5s ease-out alternate infinite;
}

@keyframes left-swing {
  50%,
  100% {
    transform: translateX(95%);
  }
}

@keyframes right-swing {
  50% {
    transform: translateX(-95%);
  }
  100% {
    transform: translateX(100%);
  }
}

.balls-parent {
  display: flex;
  flex-direction: column;
  background-color: aqua;
  position: relative;
  z-index: 9;
  justify-content: center;
  align-items: center;
  margin-top: 30%;
/*
  top: 50%;
  height:100%;
*/
}
